// Generated by Framer (90417e1)

import { addFonts, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-6Qkma"

const variantClassNames = {ayamK3kb3: "framer-v-r1fzy4"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Cju318J15TjtjNhy8n, oedZfQEgMTjtjNhy8n, bKCbkfH9zTjtjNhy8n, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "ayamK3kb3", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={Cju318J15TjtjNhy8n} openInNewTab={oedZfQEgMTjtjNhy8n} smoothScroll={bKCbkfH9zTjtjNhy8n}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-r1fzy4", className, classNames)} framer-cw7au3`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"ayamK3kb3"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-409a47fb-df9c-45e1-a84f-f309680a3ddc, rgb(54, 117, 255))", borderBottomLeftRadius: 1000, borderBottomRightRadius: 1000, borderTopLeftRadius: 1000, borderTopRightRadius: 1000, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "RlM7U2F0b3NoaS1tZWRpdW0=", "--framer-font-family": "\"Satoshi\", \"Satoshi Placeholder\", sans-serif", "--framer-font-size": "18px", "--framer-font-weight": "500", "--framer-letter-spacing": "-0.03em", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>Submit Form</motion.p></React.Fragment>} className={"framer-q1ffgt"} fonts={["FS;Satoshi-medium"]} layoutDependency={layoutDependency} layoutId={"XaQbAFNz7"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-6Qkma.framer-cw7au3, .framer-6Qkma .framer-cw7au3 { display: block; }", ".framer-6Qkma.framer-r1fzy4 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 14px 26px 14px 26px; position: relative; text-decoration: none; width: 481px; will-change: var(--framer-will-change-override, transform); }", ".framer-6Qkma .framer-q1ffgt { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-6Qkma.framer-r1fzy4 { gap: 0px; } .framer-6Qkma.framer-r1fzy4 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-6Qkma.framer-r1fzy4 > :first-child { margin-left: 0px; } .framer-6Qkma.framer-r1fzy4 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 50
 * @framerIntrinsicWidth 481
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerypEc8Md3Q: React.ComponentType<Props> = withCSS(Component, css, "framer-6Qkma") as typeof Component;
export default FramerypEc8Md3Q;

FramerypEc8Md3Q.displayName = "Form Button";

FramerypEc8Md3Q.defaultProps = {height: 50, width: 481};

addFonts(FramerypEc8Md3Q, [{explicitInter: true, fonts: [{family: "Satoshi", source: "fontshare", style: "normal", url: "https://framerusercontent.com/third-party-assets/fontshare/wf/P2LQKHE6KA6ZP4AAGN72KDWMHH6ZH3TA/ZC32TK2P7FPS5GFTL46EU6KQJA24ZYDB/7AHDUZ4A7LFLVFUIFSARGIWCRQJHISQP.woff2", weight: "500"}]}], {supportsExplicitInterCodegen: true})